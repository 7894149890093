<template>
  <div class="tb-user">
    <a-table
      :columns="columns"
      :data-source="listUsers"
      :loading="loading"
      :pagination="false"
      class="!mt-5"
      :scroll="{ x: 200, y: 400, scrollToFirstRowOnChange: true }"
    >
      <template #bodyCell="{ column, record, index }">
        <template v-if="column.key === 'action' && record">
          <a-button
            @click="onDelete(record, index)"
            :disabled="action === ACTION.VIEW"
            class="bg-transparent border-none block mx-auto"
          >
            <cds-feather-icons
              type="trash-2"
              size="20"
              :class="
                action === ACTION.VIEW ? 'text-ems-gray600' : 'text-ems-gray500'
              "
            />
          </a-button>
        </template>

        <template v-if="column.key === 'select' && record">
          <a-checkbox
            v-model:checked="record.checked"
            :disabled="action === ACTION.VIEW"
            @change="handleGetCheckboxIsSelect(record)"
          ></a-checkbox>
        </template>

        <template v-if="column.key === 'group' && record">
          <div class="flex items-center">
            <span
              ref="textElement"
              v-if="record.group.length === 1 && record.group[0].length < 12"
              class="inline-block rounded-[4px] px-3 bg-ems-gray600 truncate max-w-[120px]"
            >
              {{ record.group[0] }}
            </span>
            <a-popover
              v-if="record.group.length === 1 && record.group[0].length >= 12"
              placement="top"
              overlayClassName="popup-table-user"
            >
              <template #content>
                <span
                  class="inline-block rounded-[4px] px-3 bg-ems-gray600 text-ems-gray200"
                >
                  {{ record.group[0] }}
                </span>
              </template>
              <span
                class="inline-block rounded-[4px] px-3 bg-ems-gray600 truncate max-w-[120px]"
              >
                {{ record.group[0] }}
              </span>
            </a-popover>
            <span
              v-if="record.group.length > 1"
              class="inline-block rounded-[4px] px-3 bg-ems-gray600 truncate max-w-[120px]"
            >
              {{ record.group[1] }}
            </span>
            <a-popover placement="top" overlayClassName="popup-table-user">
              <template #content>
                <span
                  class="inline-block rounded-[4px] text-ems-gray200 text-sm px-3 bg-ems-gray600 mr-2"
                  v-for="(item, idx) in record.group"
                  :key="idx"
                >
                  {{ item }}
                </span>
              </template>
              <span
                class="inline-block rounded-[4px] px-1 bg-ems-gray600 ml-2"
                v-if="record.group.length > 1"
              >
                ...
              </span>
            </a-popover>
          </div>
        </template>
      </template>
    </a-table>
    <a-pagination
      v-if="totalUser && pagination"
      :show-size-changer="showSizeChanger"
      v-model:current="page"
      v-model:page-size="size"
      :total="totalUser"
      :page-size-options="pageSizeOptions"
      show-quick-jumper
      @change="fetchData"
      @show-size-change="sizeChange"
      :show-total="
        totalUser ? (totalUser) => `${t('common.total')}: ${totalUser}` : ''
      "
    >
    </a-pagination>
  </div>
</template>
<script setup>
import { onMounted, ref, defineEmits, computed } from 'vue';
import { i18n } from '@/main';
import { useStore } from 'vuex';
import { ACTION } from '@/config/Constant.js';
const { state, dispatch } = useStore();
const { t } = i18n.global;
const loading = ref(false);
const emits = defineEmits(['get-checkbox-is-select', 'delete-user']);
const onDelete = (record, index) => {
  emits('delete-user', record);
};
const handleGetCheckboxIsSelect = (record) => {
  dispatch('roleGroup/handleSelectMutipleUsers', record);
};
const fetchData = (page_idx) => {
  loading.value = true;
  const tbUserElement = document.querySelector('.tb-user');
  const table = tbUserElement.getElementsByClassName('ant-table-body');
  table[0].scrollTo({ top: 0, behavior: 'smooth' });
  dispatch('roleGroup/getListUsers', { page: page_idx, username: '' }).then(
    () => {
      loading.value = false;
    }
  );
};
const columns = ref([
  {
    title: t('role-group.user-name'),
    resizable: false,
    dataIndex: 'name',
    key: 'name',
    width: 200,
  },
  {
    title: t('role-group.group'),
    resizable: false,
    dataIndex: 'group',
    key: 'group',
    width: 300,
  },
  {
    title: t('role-group.email'),
    resizable: false,
    dataIndex: 'email',
    key: 'email',
    width: 200,
  },
  {
    title: t('role-group.phone'),
    resizable: false,
    dataIndex: 'phone',
    key: 'phone',
    width: 200,
  },
]);
onMounted(() => {
  if (props.isSelectUser) {
    columns.value.push({
      title: t('role-group.select'),
      dataIndex: 'select',
      key: 'select',
      width: 110,
      align: 'center',
      fixed: 'right',
    });
  } else {
    columns.value.push({
      title: t('role-group.action'),
      dataIndex: 'action',
      key: 'action',
      width: 110,
      fixed: 'right',
    });
  }
});
const props = defineProps({
  listUsers: {
    default: [],
  },
  isSelectUser: {
    default: false,
  },
  action: {
    default: 0,
  },
  totalUser: 0,
  pagination: false,
});
</script>
<style lang="scss">
.tb-user {
  --tw-bg-opacity: 1;
  background-color: rgba(15, 15, 21, var(--tw-bg-opacity));
}
.tb-user .ant-table {
  border-radius: 0px !important;
}
.tb-user .ant-table .ant-table-content {
  border: none !important;
}
.tb-user .ant-table .ant-table-thead tr th:first-child {
  border-radius: 0px !important;
}
.tb-user .ant-table .ant-table-thead tr th:last-child {
  border-radius: 0px !important;
}
.tb-user .ant-table .ant-table-thead tr th {
  border-bottom: 1px solid #2B2A3A;
  border-top: 1px solid #2B2A3A;
  --tw-bg-opacity: 1;
  background-color: rgba(24, 24, 36, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(247, 240, 247, var(--tw-text-opacity));
}
.tb-user .ant-table .ant-table-thead tr th .ant-table-filter-column {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.tb-user .ant-table .ant-table-thead tr th .ant-table-filter-column .ant-table-column-title {
  -webkit-box-flex: 0;
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
}
.tb-user .ant-table .ant-checkbox-checked .ant-checkbox-inner {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 91, 159, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(91, 91, 159, var(--tw-border-opacity));
}
.tb-user .ant-table .ant-checkbox-inner {
  background-color: transparent;
  --tw-border-opacity: 1;
  border-color: rgba(247, 240, 247, var(--tw-border-opacity));
}
.tb-user .ant-table .ant-table-tbody tr {
  --tw-text-opacity: 1;
  color: rgba(247, 240, 247, var(--tw-text-opacity));
}
.tb-user .ant-table .ant-table-tbody tr:nth-child(odd) {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 24, 36, var(--tw-bg-opacity));
}
.tb-user .ant-table .ant-table-tbody tr:nth-child(odd) td {
  background: inherit !important;
  --tw-bg-opacity: 1;
  background-color: rgba(24, 24, 36, var(--tw-bg-opacity));
}
.tb-user .ant-table .ant-table-tbody tr:nth-child(even) {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 17, 29, var(--tw-bg-opacity));
}
.tb-user .ant-table .ant-table-tbody tr:nth-child(even) td {
  background: inherit !important;
  --tw-bg-opacity: 1;
  background-color: rgba(17, 17, 29, var(--tw-bg-opacity));
}
.tb-user .ant-table .ant-table-tbody tr:hover {
  opacity: 0.9;
}
.tb-user .ant-table .ant-table-tbody tr td {
  border-bottom: 1px solid #2B2A3A;
}
.tb-user .ant-pagination {
  margin: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  padding-left: 22px;
  padding-right: 22px;
  position: absolute;
  bottom: 25px;
  right: 0px;
  width: 100%;
}
.tb-user .ant-pagination .ant-pagination-total-text {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
  height: 45px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(112, 111, 128, var(--tw-text-opacity));
}
.tb-user .ant-pagination .ant-pagination-options {
  flex: 1;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.tb-user .ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper {
  display: none;
}
.tb-user .ant-pagination .ant-pagination-options .ant-select {
  float: right;
  --tw-bg-opacity: 1;
  background-color: rgba(111, 111, 111, var(--tw-bg-opacity));
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 20px;
}
.tb-user .ant-pagination .ant-pagination-options .ant-select:before {
  position: absolute;
  content: '';
  width: 10px;
  left: 0;
  height: 6px;
  top: 0px;
  background-color: #191919;
  clip-path: polygon(100% 0%, 0% 100%, 0% 0%, 0% 0%);
}
.tb-user .ant-pagination .ant-pagination-options .ant-select:after {
  position: absolute;
  content: '';
  width: 10px;
  right: 0;
  height: 6px;
  bottom: 0px;
  background-color: #191919;
  clip-path: polygon(100% 0%, 0% 100%, 100% 100%, 100% 0%);
}
.tb-user .ant-pagination .ant-pagination-options .ant-select .ant-select-selector {
  border: none !important;
  height: 18px !important;
  clip-path: polygon(
              10px 0%,
              calc(100% + 20px) 0%,
              calc(100% - 10px) 100%,
              -20px 100%
            );
  width: calc(100% - 4px);
  border-radius: 0;
  --tw-bg-opacity: 1;
  background-color: rgba(25, 25, 25, var(--tw-bg-opacity));
}
.tb-user .ant-pagination .ant-pagination-options .ant-select .ant-select-selector .ant-select-selection-item {
  line-height: 16px !important;
  overflow: visible;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(112, 111, 128, var(--tw-text-opacity));
}
.tb-user .ant-pagination .ant-pagination-options .ant-select .ant-select-arrow {
  color: #5B5B9F;
}
.tb-user .ant-pagination .ant-pagination-options .ant-select .ant-select-dropdown {
  background-color: #191919;
}
.tb-user .ant-pagination .ant-pagination-options .ant-select .ant-select-dropdown .ant-select-item-option-content {
  --tw-text-opacity: 1;
  color: rgba(112, 111, 128, var(--tw-text-opacity));
}
.tb-user .ant-pagination .ant-pagination-item {
  background-color: #202020;
  color: #b4b4b4;
  font-size: 14px;
  border: none !important;
  margin-bottom: 13px !important;
}
.tb-user .ant-pagination .ant-pagination-item-active {
  background-color: #5B5B9F !important;
  color: #f5f5f5;
  text-shadow: 0px 0px 6px #fff;
  border: none !important;
}
.tb-user .ant-pagination .ant-pagination-next {
  border: none !important;
  margin-bottom: 13px !important;
}
.tb-user .ant-pagination .ant-pagination-prev {
  border: none !important;
  margin-bottom: 13px !important;
}
.tb-user .ant-pagination .ant-pagination-jump-next, .tb-user .ant-pagination .ant-pagination-jump-prev {
  border: none !important;
  margin-bottom: 13px !important;
}
.tb-user .ant-pagination .ant-pagination-item-link {
  --tw-bg-opacity: 1;
  background-color: rgba(32, 32, 32, var(--tw-bg-opacity));
  border-radius: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  --tw-text-opacity: 1;
  color: rgba(91, 91, 159, var(--tw-text-opacity));
}
</style>
