<template>
  <div class="list-users">
    <div class="mt-[30px] flex items-center justify-between">
      <span v-if="isSelecting" class="text-base font-semibold text-white">{{
        t('role-group.selecting-user')
      }}</span>
      <span v-else class="text-base font-semibold text-white"
        >{{ t('role-group.list-user') }}
        <span class="text-ems-main2"
          >{{ formState.listUsers.length + t('role-group.user') }}
        </span></span
      >
      <div class="flex justify-between gap-4 items-center">
        <a-input
          size="large"
          :placeholder="t('role-group.placeholder-search')"
          v-model:value="searchValue"
          class="!w-[350px] ant-input-sm rounded-[8px] bg-ems-tag1 border-none"
          maxLength="255"
        >
          <template #prefix>
            <img
              src="@/static/img/group-management/input-search.svg"
              alt="input-suffix"
              height="16"
              width="16"
            />
          </template>
        </a-input>
        <a-button
          @click="selectingUser"
          :disabled="action === ACTION.VIEW"
          class="border-none px-[6px] rounded-[8px]"
          :class="action === ACTION.VIEW ? '!bg-ems-gray700' : 'bg-ems-main2'"
        >
          <cds-feather-icons
            type="plus"
            size="20"
            :class="
              action === ACTION.VIEW ? 'text-ems-gray600' : 'text-ems-gray200'
            "
          />
        </a-button>
      </div>
    </div>
    <TableUser
      v-if="isSelecting"
      :isSelectUser="true"
      :listUsers="listDataUser"
      :action="action"
      :totalUser="totalUser"
      :pagination="true"
    />
    <TableUser
      v-else
      :isSelectUser="false"
      :listUsers="listOfSelectedUsers"
      @delete-user="onDelete"
      :action="action"
      :totalUser="totalUser"
    />
  </div>
</template>
<script setup>
import { computed, ref, defineEmits, onMounted, watch } from 'vue';
import { i18n } from '@/main';
import { useStore } from 'vuex';
import TableUser from './TableUser.vue';
import { ACTION } from '@/config/Constant.js';
const { t } = i18n.global;

const { state, dispatch } = useStore();
const searchValue = ref('');
const action = computed(() => state.roleGroup.action);
const listDataUser = ref(computed(() => state.roleGroup.users));
const roleId = ref(computed(() => state.roleGroup.roleId));
const listOfSelectedUsers = ref(
  computed(() => state.roleGroup.listUserInRole)
);
const totalUser = ref(computed(() => state.roleGroup.totalUser));

watch(searchValue, (newValue, oldValue) => {
  dispatch('roleGroup/getListUsers', { page: 1, username: newValue });
  computed(() => state.roleGroup.group.listUsers)
  dispatch('roleGroup/getListUserInRole',{roleId:roleId.value,query:newValue});
});
const handleInitSelect = () => {
  listDataUser.value.map((item) => {
    item.checked = false;
  });
  if (action.value === 1) {
    listDataUser.value.forEach((parentItem) => {
      const childMatch = listOfSelectedUsers.value.find(
        (childItem) => childItem.id === parentItem.id
      );
      if (childMatch) {
        parentItem.checked = true;
      }
    });
  }
};
onMounted(() => {
  handleInitSelect();
});
watch(listDataUser, (newValue, oldValue) => {
  handleInitSelect();
});

const onDelete = (record, index) => {
  const idToDelete = listOfSelectedUsers.value.findIndex(
    (item) => item.id === record.id
  );
  if (idToDelete !== -1) {
    listOfSelectedUsers.value.splice(idToDelete, 1);
  }
  const idToUpdate = listDataUser.value.findIndex(
    (item) => item.id === record.id
  );
  if (idToUpdate !== -1) {
    listDataUser.value[idToUpdate].checked = false;
  }
};
const emits = defineEmits(['selecting-user', 'get-checkbox-is-select']);

const selectingUser = () => {
  searchValue.value = '';
  emits('selecting-user');
};

const props = defineProps({
  formState: {
    default: {},
  },
  listUsers: {
    default: [],
  },
  isSelecting: {
    default: false,
  },
  action: {
    default: 0,
  },
});
</script>
<style lang="scss">
.list-users .ant-input-sm input.ant-input {
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgba(247, 240, 247, var(--tw-text-opacity));
}
.list-users .ant-input-sm input.ant-input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(247, 240, 247, var(--tw-text-opacity));
}
.list-users .ant-btn:active, .list-users .ant-btn:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 91, 159, var(--tw-bg-opacity));
}
.list-users .ant-btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 91, 159, var(--tw-bg-opacity));
}
</style>
